export default [
  {
    path: '/combo',
    component: () => import('@/views/plus/promo/Combo'),
  },
  {
    path: '/cart',
    component: () => import('@/views/plus/cart/Cart'),
    props: true,
  },
  {
    path: '/promo',
    component: () => import('@/views/plus/promo/Promo'),
  },
  {
    path: '/compare',
    component: () => import('@/views/plus/promo/Compare'),
  },
  {
    path: '/what',
    component: () => import('@/views/plus/promo/WhatIs'),
  },
  {
    path: '/plus',
    name: 'plus',
    redirect: '/plus/welcome',
    component: () => import('@/views/plus/Plus'),
    children: [
      {
        path: 'welcome',
        component: () => import('@/views/plus/Welcome'),
      },
      {
        path: 'listed',
        component: () => import('@/views/plus/Listed'),
      },
      {
        path: 'setup',
        component: () => import('@/views/plus/Setup'),
      },
      {
        path: 'market',
        component: () => import('@/views/plus/Market'),
      },
      {
        path: 'market-advanced',
        component: () => import('@/views/plus/MarketAdvanced'),
      },
      {
        path: 'futures',
        component: () => import('@/views/plus/Futures'),
      },
      {
        path: 'livestock',
        component: () => import('@/views/plus/Livestock'),
      },
      {
        path: 'weather',
        component: () => import('@/views/plus/Weather'),
      },
      {
        path: 'twitter',
        component: () => import('@/views/plus/Twitter'),
      },
      {
        path: 'web',
        component: () => import('@/views/plus/Web'),
      },
      {
        path: 'reports',
        component: () => import('@/views/plus/Reports'),
      },
      {
        path: '/stripe',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'payment-success',
            component: () => import('@/views/plus/stripe/StripeRedirect'),
          },
          {
            path: 'payment-cancel',
            component: () => import('@/views/plus/stripe/StripeRedirect'),
          },
        ]
      }
    ]
  }
]
