import axios from 'axios'
import store from '@/state/store'
import router from '@/router'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
//axios.defaults.baseURL = "http://localhost:44388/api"


axios.defaults.validateStatus = (status) => {
  return status >= 200 && status < 500
}
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['accept'] = '*/*'

axios.interceptors.request.use(async request => {
  //console.info('Axios', 'request', request.method, request.url)
  //console.debug('Axios', 'request', JSON.stringify(request, null, 2))

  // Continue without token checks on "unsecured" requests
  if (request.unsecured) {
    //console.log('Axios', 'request', 'unsecured', request.url)
    return request
  }

  // If we are logged in but the access token is expired then refresh it using the refresh token first
  const token = store.state.auth.tokenPayload
  const expDate = new Date(token.exp * 1000)
  if (expDate < new Date()) {

    // If the refresh token is also expired then log out
    const refreshTokenExpiration = new Date(store.state.auth.refreshTokenExpiration + 'Z')
    if (refreshTokenExpiration < new Date()) {
      //console.debug('axios.interceptors.request', 'Refresh token expired, logging out...')
      await store.dispatch('auth/logout')
      return router.push({name: 'Login', query: {from: router.currentRoute.path}})
    } else {
      //console.debug('axios.interceptors.request', 'Refreshing access token...')
      try {
        await store.dispatch('auth/login', {})
      } catch (e) {
        console.error(e)
        console.debug('axios.interceptors.request', 'Refresh token failed, logging out...')
        await store.dispatch('auth/logout')
        return router.push({name: 'Login', query: {from: router.currentRoute.path}})
      }
    }
  }

  // Add bearer token to request
  if ((store.getters['auth/isLoggedIn'])) {
    request.headers.common['Authorization'] = 'Bearer ' + store.state.auth.token
  }
  return request
  }, error => {
    console.debug('Axios', 'request', 'error', error)
  })
  axios.interceptors.response.use(response => {
    console.debug('Axios', 'response', JSON.stringify(response, null, 2))

    // Handle API business logic exceptions
    if (response.status >= 400 && response.status < 500) {
      const error = new Error(
        response.data?.responseException?.exceptionMessage?.title || 
        String(response.data?.responseException?.exceptionMessage) ||
        response.statusText
      )
      error.errors = response.data?.responseException?.exceptionMessage?.errors
      error.errorList = response.data?.responseException?.exceptionMessage?.errorList
      throw error
    }
  return response.config.responseType === 'blob' ? response : response.data?.result
}, error => {
  console.debug('Axios', 'response', 'error', JSON.stringify(error))
  throw error
})

export default axios