export default [
  {
    path: '/search',
    name: 'Search',
    props: true,
    component: () => import('@/views/search/OpenOffers'),
    children: [
      {
        path: ':lot',
        name: 'SearchOffer',
        props: true,
        meta: {
          showBreadcrumbs: true,
          defaultLabel: 'Offer',
          isDynamic: true
        },
        component: () => import('@/views/search/ViewOffer')
      },
    ]
  },
  {
    path: '/offers',
    name: 'My Offers',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/offer/MyOffers'),
    children: [
      {
        path: ':lot',
        name: 'ViewMyOffer',
        props: true,
        meta: {
          showBreadcrumbs: true,
          defaultLabel: 'Offer',
          isDynamic: true
        },
        component: () => import('@/views/search/ViewOffer')
      },
    ]
  },
  {
    path: '/offer',
    name: 'Offer',
    meta: {
      requiresAuth: true,
    },
    props: true,
    // redirect: to => {
    //       return '/offer/flow'
    //   // if (window.innerWidth <= 576) return '/offer/flow'
    //   // return '/offer/form'
    // },
    // component: () => import('@/views/offer/OfferContainer'),
    redirect: to => {
        console.log('offer router: create')
        return { name: 'Offer form single' }
      
    },
    component: () => import('@/views/offer/OfferContainerSingle'),

    // Desktop
    children: [
      {
        path: 'form',
        name: 'Offer form',
        meta: {
          steps: ['Create', 'Preview', 'Confirm'],
          step: 'Create',
          reload:true
        },
        
        component: () => import('@/views/offer/OfferForm')
      },
      {
        path: 'preview',
        name: 'Offer preview',
        meta: {
          step: 'Preview'
        },
        component: () => import('@/views/offer/OfferPreview')
      },
      {
        path: 'flow-single',
        name: 'Offer form single',
        props: true,
        meta: {
          steps: ['Create', 'Preview', 'Confirm'],
          step: 'Create'
        },
        component: () => import('@/views/offer/OfferFormSingle')
      },
      {
        path: ':offerKey',
        name: 'OfferEdit',
        props: true,
        meta: {
          steps: ['Edit', 'Preview', 'Confirm'],
          step: 'Edit',
          isDynamic: true
        },
        component: () => import('@/views/offer/OfferFormSingle')
      },
      {
        path: 'preview',
        name: 'Offer preview single',
        meta: {
          step: 'Preview'
        },
        component: () => import('@/views/offer/OfferPreview')
      },

      // Mobile
      {
        path: 'flow',
        name: 'Offer flow',
        redirect: '/offer/flow/grain',
        component: () => import('@/views/offer/OfferFlow'),
        children: [
          {
            path: 'grain',
            name: 'Offer flow grain',
            props: true,
            meta: {
              steps: ['Grain', 'Storage', 'Contract', 'Confirm'],
              step: 'Grain',
              nextStepPath: 'storage'
            },
            component: () => import('@/views/offer/form/Grain')
          },
          {
            path: 'storage',
            name: 'Offer flow storage',
            meta: {
              step: 'Storage',
              nextStepPath: 'contract'
            },
            component: () => import('@/views/offer/form/Storage')
          },
          {
            path: 'contract',
            name: 'Offer flow contract',
            meta: {
              step: 'Contract',
              nextStepPath: 'preview'
            },
            component: () => import('@/views/offer/form/Contract')
          },
          {
            path: 'preview',
            name: 'Offer flow preview',
            meta: {
              step: 'Confirm',
              title: 'Confirm your offer'
            },
            component: () => import('@/views/offer/form/Preview')
          }
        ]
      }
    ]
  },
  /*
  {
    path: '/offer',
    name: 'OfferEdit',
    meta: {
      requiresAuth: true,
      isDynamic: true
    },
    // redirect: to => {
    //       return '/offer/flow'
    //   // if (window.innerWidth <= 576) return '/offer/flow'
    //   // return '/offer/form'
    // },
    // component: () => import('@/views/offer/OfferContainer'),
    redirect: to => {
      console.log('offer edit router: ' + JSON.stringify(to.params))
      return { name: 'Offer edit form single', params: { offerKey: to.params.offerKey }  }
      
   
    },
    component: () => import('@/views/offer/OfferContainerSingle'),

    // Desktop
    children: [
      

      {
        path: 'edit/:offerKey',
        name: 'Offer edit form single',
        props: true,
        meta: {
          steps: ['Create', 'Preview', 'Confirm'],
          step: 'Create'
        },
        component: () => import('@/views/offer/OfferFormSingle')
      },
      {
        path: 'preview',
        name: 'Offer preview single',
        meta: {
          step: 'Preview'
        },
        component: () => import('@/views/offer/OfferPreview')
      }
    ]}
*/
  
]