import Vue from 'vue'
import Router from 'vue-router'

// Router modules
import admin from './admin'
import auth from './auth'
import user from './user'
import offer from './offer'
import bid from './bid'
import plus from './plus'
import migration from './migration'

// Containers
const Container = () => import('@/views/home/Container')

// Views
const Home = () => import('@/views/home/Home')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')

// Store
import store from '@/state/store'

Vue.use(Router)

const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

router.beforeEach((to, from, next) => {
  console.log('router.beforeEach. to: '+ to.name + ' from: ' + from.name + ' next: ' + JSON.stringify(next))
  const requiresAuth = to.matched.some((route) => route.meta.requiresAuth)
  if (!requiresAuth) return next()
  if (!store.getters['auth/isLoggedIn']) {
    return next({name: 'Login', query: {from: to.fullPath}})
  }
  next()
})

router.onError(error => {
  console.error(error)
  if (/loading chunk \d* failed./i.test(error.message)) {
    console.log('Vue router reloading window due to chunk error')
    window.location.reload()
  }
})

function configRoutes () {
  return [
    admin,
    ...auth,
    {
      path: '/',
      component: Container,
      children: [
        {
          path: '',
          name: 'Home',
          component: Home
        },
        {
          path: 'about',
          name: 'About',
          component: () => import('@/views/home/About')
        },
        {
          path: 'privacy',
          name: 'Privacy policy',
          component: () => import('@/views/home/Privacy')
        },
        ...offer,
        ...bid,
        ...plus,
        ...user,
        ...migration,
      ]
    },
    {
      path: '*',
      redirect: '/pages/404'
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        }
      ]
    }
  ]
}

export default router